import { Pencil } from '@cfa-icons/system';
import { IconPlus } from '@tabler/icons-react';
import {
  Card,
  Checkbox,
  Col,
  Dropdown,
  Icon,
  Row,
  TextField,
  Typography,
} from 'cfa-react-components';
import { useContext, useRef, useState } from 'react';
import '../../../../App.scss';
import './AddDescriptor.scss';

import { DescriptorItem, Descriptors } from 'app/types/Descriptors';
import { useRevertEdit } from 'app/utils/hooks/useRevertEdit';
import { DescriptorsContext } from './DescriptorsContext';

export type AddDescriptorProps = {
  descriptorDropdown: boolean;
  setShowAll: (value: React.SetStateAction<boolean>) => void;
};

export const AddDescriptor = ({
  descriptorDropdown = false,
  setShowAll,
}: AddDescriptorProps) => {
  const { descriptors, setDescriptors } = useContext(DescriptorsContext);
  const [descriptorAddMode, setDescriptorAddMode] = useState(false);
  const [includeLink, setIncludeLink] = useState(false);
  const [descriptorInput, setDescriptorInput] = useState('');
  const [valueInput, setValueInput] = useState('');
  const [linkInput, setLinkInput] = useState('');
  const [valueError, setValueError] = useState(false);
  const [descriptorError, setDescriptorError] = useState(false);
  const [renderDropdown, setRenderDropdown] = useState(descriptorDropdown);
  const [descriptorOptions, setDescriptorOptions] = useState([
    'Enter Custom Descriptor',
    'Team Communication Channel',
    'Prod Support',
    'Questions for the Team',
    'Services',
    'External Partners',
    'Development Partners',
    'Repos',
    'Tools',
    'Tech Stack',
  ]);

  const cardRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLButtonElement>(null);

  const toggleIncludeLink = () => {
    if (!includeLink) {
      setLinkInput('');
    }
    setIncludeLink(prev => !prev);
  };

  const addDescriptorItem = (
    descriptors: Descriptors | undefined,
    descriptorInput: string,
    linkInput: string,
    valueInput: string,
  ): Descriptors => {
    // If descriptors is undefined, initialize it as an empty object
    const updatedDescriptors = descriptors || {};

    const newItem: DescriptorItem = {
      link: linkInput.trim() ? linkInput : null,
      value: valueInput,
    };

    // Check if the descriptor name already exists in the descriptors object
    if (updatedDescriptors[descriptorInput]) {
      // Add the new item to the existing descriptor array
      return {
        ...updatedDescriptors,
        [descriptorInput]: [...updatedDescriptors[descriptorInput], newItem],
      };
    } else {
      // Create a new descriptor with the new item
      return {
        ...updatedDescriptors,
        [descriptorInput]: [newItem],
      };
    }
  };

  const resetAllFields = () => {
    setDescriptorInput('');
    setValueInput('');
    setLinkInput('');
    setIncludeLink(false);
    setDescriptorAddMode(false);
    setDescriptorError(false);
    setValueError(false);
    setRenderDropdown(true);
  };

  const handleAddDescriptor = () => {
    if (!descriptorInput.trim() && valueInput.trim()) {
      setDescriptorError(true);
      setDescriptorAddMode(true);
      return;
    }
    if (descriptorInput.trim() && !valueInput.trim()) {
      setValueError(true);
      setDescriptorAddMode(true);
      return;
    }
    if (!descriptorInput.trim() && !valueInput.trim()) {
      resetAllFields();
      return;
    }

    setDescriptors(
      addDescriptorItem(
        descriptors,
        descriptorInput.trim(),
        linkInput.trim(),
        valueInput.trim(),
      ),
    );
    resetAllFields();
  };

  useRevertEdit(
    cardRef,
    setDescriptorAddMode,
    'infoPencil',
    handleAddDescriptor,
  );

  const handleOpenMenu = () => {
    // Add existing options to dropdown
    if (descriptorDropdown) {
      setDescriptorOptions(prevOptions => [
        ...Array.from(
          new Set([...prevOptions, ...Object.keys(descriptors as Object)]),
        ),
      ]);
    }
    setDescriptorAddMode(true);
    setShowAll(true);
  };

  const renderDropdownOptions = (option: string) => {
    if (option === 'Enter Custom Descriptor') {
      return (
        <>
          <Icon className="new-descriptor-icon" icon={Pencil} />
          <Typography fontWeight="bold" color="secondary">
            {option}
          </Typography>
        </>
      );
    }
    return <>{option}</>;
  };

  const handleDropdownClick = (value: string | null) => {
    if (value == 'Enter Custom Descriptor') {
      setRenderDropdown(false);
      setDescriptorInput('');
      return;
    }
    setDescriptorInput(value!);
  };

  return (
    <>
      <Row
        data-cy="add-descriptor-btn"
        className="add-descriptor-btn"
        onClick={() => handleOpenMenu()}
      >
        <Icon className="add-descriptor-icon" icon={IconPlus} />
        <Typography fontWeight="bold" color="secondary">
          Add Descriptor
        </Typography>
      </Row>
      {descriptorAddMode && (
        <Card
          ref={cardRef}
          className="descriptor-box"
          variant="default"
          elevation={1}
        >
          <Icon
            onClick={handleAddDescriptor}
            className="descriptor-box-edit-icon"
            icon={Pencil}
            size="sm"
            color="grey"
          />
          <div className="edit-descriptor-row">
            <Col>
              {renderDropdown ? (
                <Dropdown
                  ref={dropdownRef}
                  className="descriptor-name-dropdown"
                  label="Descriptor"
                  required
                  fullWidth
                  options={descriptorOptions}
                  onChange={handleDropdownClick}
                  value={descriptorInput}
                  renderOption={renderDropdownOptions}
                  data-cy="descriptor-name-dropdown"
                  placeholder="Choose descriptor name"
                  {...(descriptorError &&
                    !descriptorInput.trim() && {
                      errorText: 'You must choose a descriptor name',
                    })}
                />
              ) : (
                <TextField
                  label="Descriptor"
                  fullWidth
                  required
                  data-cy="descriptor-name-text-field"
                  value={descriptorInput}
                  onChange={e => setDescriptorInput(e.target.value)}
                  {...(descriptorError &&
                    !descriptorInput.trim() && {
                      errorText: 'You must enter a descriptor name',
                    })}
                  placeholder="Enter descriptor name..."
                />
              )}
            </Col>
            <Col>
              <TextField
                data-cy="descriptor-value-text-field"
                label="Value"
                fullWidth
                required
                maxLength={200}
                value={valueInput}
                onChange={e => setValueInput(e.target.value)}
                helperText=" "
                {...(valueError &&
                  !valueInput.trim() && {
                    errorText: 'You must enter a value',
                  })}
                placeholder="Enter descriptor value..."
              />
              {includeLink && (
                <TextField
                  data-cy="descriptor-link-value"
                  className="link-text-field"
                  label="Link"
                  fullWidth
                  value={linkInput}
                  onChange={e => setLinkInput(e.target.value)}
                  helperText=" "
                  placeholder="Enter descriptor link..."
                />
              )}
              <Checkbox
                data-cy="add-descriptor-link-checkbox"
                label="Include Link"
                checked={includeLink}
                onChange={toggleIncludeLink}
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
            </Col>
          </div>
        </Card>
      )}
    </>
  );
};

export default AddDescriptor;
