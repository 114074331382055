import Footer from 'app/components/Footer/Footer';
import { CreateLTButton } from 'app/components/shared/FormDialog/CreateLeadershipTeam';
import LeadershipFAQ from 'app/components/shared/FormDialog/LeadershipFAQ';
import { LeadershipCard } from 'app/components/shared/LeadershipCard/LeadershipCard';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  clearBreadcrumbAction,
  replaceBreadcrumbsAction,
} from 'app/store/breadcrumbs/actions';
import { selectIsNavigatingBack } from 'app/store/breadcrumbs/selectors';
import { selectDepartments } from 'app/store/dept/selectors';
import { selectLeadership } from 'app/store/leadership/selectors';
import { useLeadershipRoles } from 'app/store/roles/selectors';
import Department from 'app/types/Department';
import { LeadershipTeam } from 'app/types/Leadership';
import { Role } from 'app/types/Role';
import { handleBackToSearchResults } from 'app/utils/breadcrumbSessionStorage';
import { allowEditLT } from 'app/utils/hasPermissions/allowFeature';
import { Divider, List, ListItem, Typography } from 'cfa-react-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../components/Navigation/Navigation';
import './Leadership.scss';

export const LeadershipTeams = () => {
  const deptList: Department[] = useAppSelector(selectDepartments);
  const rolesList: Role[] = useLeadershipRoles();
  const leadershipList: LeadershipTeam[] = useAppSelector(selectLeadership);
  const allowLTCreate: boolean = allowEditLT();
  const fromSearch = sessionStorage.getItem('fromSearch');
  const isNavigatingBack = useAppSelector(selectIsNavigatingBack);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // using session storage to see where user comes from
    if (fromSearch === 'true') {
      handleBackToSearchResults(dispatch, navigate);
    } else {
      if (isNavigatingBack) {
        console.log('NAVIGATING BACK');
        console.log(isNavigatingBack);
        return; // Exit if pod is not yet loaded or navigating back
      } else {
        const leadershipBreadcrumb = {
          label: 'Leadership Teams',
          onClick: () => {
            dispatch(clearBreadcrumbAction());
            navigate('/leadership-teams');
          },
        };
        dispatch(replaceBreadcrumbsAction([leadershipBreadcrumb]));
      }
    }
  }, []);

  return (
    <>
      <Navigation />
      <div className="main-container">
        <div className="title-section">
          <Typography
            variant="h2"
            data-cy="leadership-teams-title"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            Leadership Teams
          </Typography>
        </div>
        <Divider orientation="horizontal" variant="middle" />

        <List
          style={{
            columnGap: '10px',
            padding: '30px',
            textAlign: 'left',
            marginBlockStart: '1rem',
            counterReset: 'grid',
            columns: '18rem',
          }}
        >
          {leadershipList.map(leadership => {
            return (
              <ListItem
                key={leadership.id}
                style={{
                  minWidth: '258px',
                  maxWidth: '258px',
                  breakInside: 'avoid',
                }}
              >
                <LeadershipCard
                  rolesList={rolesList}
                  leadership={leadership}
                  leadershipMembers={leadership.members ?? []}
                  deptList={deptList}
                />
              </ListItem>
            );
          })}
        </List>
      </div>
      <div className="create-lt-button">
        <CreateLTButton allowCreate={allowLTCreate} />
        <LeadershipFAQ />
      </div>
      <Footer />
    </>
  );
};

export default LeadershipTeams;
