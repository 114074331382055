import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { replaceBreadcrumbsAction } from '../store/breadcrumbs/actions';

export const handleBackToSearchResults = (
  dispatch: Dispatch<any>,
  navigate: NavigateFunction,
): void => {
  const searchQuery = sessionStorage.getItem('searchQuery') || '';

  dispatch(
    replaceBreadcrumbsAction([
      {
        label: 'Back to Search Results',
        onClick: () => {
          navigate(`/search?q=${searchQuery}`);
        },
      },
    ]),
  );
};
