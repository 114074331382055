import { ChevronDown, ChevronUp } from '@cfa-icons/system';
import { Icon, Row, Typography } from 'cfa-react-components';
import { useContext, useState } from 'react';
import '../../../../App.scss';
import { AddDescriptor } from './AddDescriptor';
import { DescriptorBox } from './DescriptorBox';
import './DescriptorBoxEditor.scss';
import { DescriptorsContext } from './DescriptorsContext';

export const DescriptorBoxEditor = () => {
  const { descriptors } = useContext(DescriptorsContext);
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="descriptor-box-editor-wrapper">
      <AddDescriptor setShowAll={setShowAll} descriptorDropdown={true} />
      {descriptors &&
        Object.entries(descriptors)
          .slice(0, showAll ? Object.entries(descriptors).length : 2)
          .map(([name, items]) => (
            <DescriptorBox key={name} name={name} items={items} />
          ))}
      {descriptors && Object.entries(descriptors).length > 2 && (
        <Row className="more-descriptors-btn" onClick={() => handleShowAll()}>
          <Typography fontWeight="medium" color="default">
            {showAll ? 'Less' : 'More'} Descriptors
          </Typography>
          <Icon
            className="more-descriptors-icon"
            icon={showAll ? ChevronUp : ChevronDown}
          />
        </Row>
      )}
    </div>
  );
};

export default DescriptorBoxEditor;
