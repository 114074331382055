// slice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BreadcrumbProps } from 'cfa-react-components';
import ApiStatus from '../../constants/ApiState';

interface BreadcrumbsState {
  breadcrumbs: BreadcrumbProps[];
  isNavigatingBack: boolean;
  apiStatus: {
    loadBreadcrumbs: ApiStatus;
    updateBreadcrumbs: ApiStatus;
    addBreadcrumbs: ApiStatus.IDLE;
    removeBreadcrumbs: ApiStatus.IDLE;
  };
}

const initialState: BreadcrumbsState = {
  breadcrumbs: [],
  apiStatus: {
    loadBreadcrumbs: ApiStatus.IDLE,
    updateBreadcrumbs: ApiStatus.IDLE,
    addBreadcrumbs: ApiStatus.IDLE,
    removeBreadcrumbs: ApiStatus.IDLE,
  },
  isNavigatingBack: false,
};

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    pushBreadcrumb: (state, action: PayloadAction<BreadcrumbProps>) => {
      state.breadcrumbs.push(action.payload);
    },
    popBreadcrumb: state => {
      state.breadcrumbs.pop();
    },
    replaceBreadcrumbs: (state, action: PayloadAction<BreadcrumbProps[]>) => {
      state.breadcrumbs = action.payload;
    },
    clearBreadcrumbs: state => {
      state.breadcrumbs = [];
    },
    setIsNavigatingBack: (state, action: PayloadAction<boolean>) => {
      state.isNavigatingBack = action.payload;
    },
  },
});

export const {
  pushBreadcrumb,
  popBreadcrumb,
  replaceBreadcrumbs,
  clearBreadcrumbs,
  setIsNavigatingBack,
} = breadcrumbsSlice.actions;

export default breadcrumbsSlice;
