// PodsProfilePage.tsx
import { ChevronLeft } from '@cfa-icons/system';
import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import MemberInfoSection from 'app/components/shared/CardRoleGroups/MemberInfoSection';
import { CardTag } from 'app/components/shared/CardTag/CardTag';
import { PictureDownloadButton } from 'app/components/shared/PictureDownloadButton/PictureDownloadButton';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  popBreadcrumbAction,
  pushBreadcrumbAction,
  setIsNavigatingBackAction,
} from 'app/store/breadcrumbs/actions';
import {
  selectIsNavigatingBack,
  useBreadcrumbs,
} from 'app/store/breadcrumbs/selectors';
import { selectDepartments } from 'app/store/dept/selectors';
import { selectPodById } from 'app/store/pods/selectors';
import { EmployeeImageData } from 'app/types/ImageTypes';
import { handleBackToSearchResults } from 'app/utils/breadcrumbSessionStorage';
import { allowCreateEditPod } from 'app/utils/hasPermissions/allowFeature';
import { usePodMembers } from 'app/utils/hooks/useMembers';
import {
  Breadcrumbs,
  Button,
  Divider,
  Icon,
  List,
  Switch,
  Tooltip,
  Typography,
} from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound';
import './PodStyling.scss';

export const PodsProfilePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { podId } = useParams() as { podId: string };
  const pod = useAppSelector(selectPodById(podId));
  const allowPodCreateEdit: boolean = allowCreateEditPod();

  const [staffOnly, setStaffOnly] = useState(false);
  const [backButtonBoolean, setBackButtonBoolean] = useState<boolean>(false);
  const podMembers = usePodMembers(podId, staffOnly);

  const [userImageData, setUserImageData] = useState<EmployeeImageData[]>([]);
  const departments = useAppSelector(selectDepartments);
  const { breadcrumbs } = useBreadcrumbs();
  const isNavigatingBack = useAppSelector(selectIsNavigatingBack);
  const dispatch = useAppDispatch();
  let dept = departments.find(dept => dept.id === pod?.deptId) ?? undefined;
  const EditPods = () => {
    navigate(`/pods/${pod?.id}/edit`);
  };

  const [cleanedUpdated, setCleanedUpdated] = useState('');
  const fromSearch = sessionStorage.getItem('fromSearch');

  useEffect(() => {
    if (fromSearch === 'true') {
      handleBackToSearchResults(dispatch, navigate);
    } else {
      if (!pod || isNavigatingBack) {
        return;
      } else {
        // const isPodDetailsPage = location.pathname === `/pods/${podId}`;

        const podBreadcrumb = {
          label: pod?.name || '',
          onClick: () => {
            navigate(`/pods/${podId}`);
            dispatch(popBreadcrumbAction());
            dispatch(popBreadcrumbAction());
          },
        };
        const breadcrumbExists = (() => {
          if (breadcrumbs.length === 0) {
            return false;
          }

          const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

          return lastBreadcrumb.label === podBreadcrumb.label;
        })();

        if (!breadcrumbExists) {
          dispatch(pushBreadcrumbAction(podBreadcrumb));
        }
      }
    }
  }, [pod?.updatedAt]);

  const handleBackClick = () => {
    dispatch(setIsNavigatingBackAction(true)); // Set the flag
    dispatch(popBreadcrumbAction());
    window.history.back(); // Go back to the previous page

    // Clear the flag after a short delay to allow the component to re-render
    setTimeout(() => {
      dispatch(setIsNavigatingBackAction(false));
    }, 0);
  };

  const sortedTags = pod?.tags
    ? [...pod.tags].sort((a, b) => {
        const tagA = a.toLowerCase();
        const tagB = b.toLowerCase();
        return tagA.localeCompare(tagB);
      })
    : [];
  return pod ? (
    <>
      <Navigation />
      <div className="main-container">
        <div className="top-section">
          <div className="navigation-breadcrumbs-wrapper">
            {fromSearch && (
              <Icon icon={ChevronLeft} className="info" size="sm" />
            )}
            {breadcrumbs.length > 3 ? (
              <>
                <Icon icon={ChevronLeft} className="info" size="sm" />
                <Breadcrumbs
                  breadcrumbs={[
                    {
                      label: 'Back',
                      onClick: handleBackClick,
                    },
                  ]}
                />
              </>
            ) : (
              <>
                <Breadcrumbs
                  data-cy="pod-profile-breadcrumb"
                  maxItems={4}
                  breadcrumbs={breadcrumbs}
                />
              </>
            )}
          </div>
          {allowPodCreateEdit ? (
            <Button
              color="primary"
              onClick={EditPods}
              size="md"
              variant="outlined"
              style={{
                justifySelf: 'end',
              }}
              disabled={!allowPodCreateEdit}
              data-cy="pod-edit-btn-allowed"
            >
              Edit Pod
            </Button>
          ) : (
            <Tooltip content="Insufficient Permissions">
              <span>
                <Button
                  color="primary"
                  onClick={function noRefCheck() {}}
                  size="md"
                  variant="outlined"
                  style={{
                    justifySelf: 'end',
                  }}
                  disabled={!allowPodCreateEdit}
                  data-cy="pod-edit-btn-disallowed"
                >
                  Edit Pod
                </Button>
              </span>
            </Tooltip>
          )}
        </div>
        <div>
          <Typography
            variant="caption1"
            fontWeight="regular"
            style={{
              marginTop: '5px',
              marginRight: '3vh',
              float: 'right',
              color: '#A7ACAF',
            }}
          >
            {cleanedUpdated}
          </Typography>
        </div>
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
            data-cy="pod-profile-name"
          >
            {pod.name}
            <div style={{ color: '#80878C', fontSize: '22px' }}>
              {dept?.name}
            </div>
            <div
              data-cy="pods-profile-tags"
              style={{
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '600px',
                flexWrap: 'wrap',
                alignContent: 'center',
              }}
            >
              {sortedTags?.map((tag, index) => (
                <CardTag key={index} tag={tag} />
              ))}
            </div>
          </Typography>
        </div>
        <div className="pod-info">
          <Typography
            variant="body1"
            color="secondary"
            fontWeight="medium"
            align="center"
            gutterBottom
            style={{
              marginTop: '40px',
              marginLeft: '85px',
              marginRight: '85px',
              margin: '0 auto',
            }}
            data-cy="pod-info"
          >
            {pod.info}
          </Typography>
        </div>
        <div
          style={{
            width: '800px',
            margin: '0 auto',
            marginTop: '20px',
          }}
        >
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px' }}
          ></Divider>
          <div id="imageDownload">
            <List
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gridTemplateColumns: 'repeat(4, 1fr)',
                justifyContent: 'center',
                alignItems: 'stretch',
              }}
            >
              {podMembers
                .filter(member => {
                  return !staffOnly || !member.isContingent;
                })
                .map((member, index) => (
                  <div
                    key={member.userId}
                    style={{
                      flexBasis: '20%',
                      minWidth: '150px',
                    }}
                  >
                    <MemberInfoSection
                      member={member}
                      index={index}
                      setUserImageData={setUserImageData}
                    />
                  </div>
                ))}
            </List>
          </div>
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px' }}
          ></Divider>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
            }}
          >
            <Switch
              data-cy="toggle"
              aria-label="Filter staff?"
              label="Staff Only"
              crossOrigin={undefined}
              checked={staffOnly}
              onChange={e => {
                setStaffOnly(e.currentTarget.checked);
              }}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
            <PictureDownloadButton
              userImageData={userImageData}
              staffOnly={staffOnly}
              name={pod.name}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <PageNotFound></PageNotFound>
  );
};
