import { Plus, UserPlus } from '@cfa-icons/system';
import { SaveAndCloseButtons } from 'app/components/shared/ProfilePages/SaveAndCloseButtons';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import { createPointOfContact } from 'app/store/points_of_contact/actions';
import { selectPointsOfContactByDeptId } from 'app/store/points_of_contact/selectors';
import Department from 'app/types/Department';
import IdentityUser from 'app/types/IdentityUser';
import Role from 'app/types/Role';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Container,
  Divider,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalHeader,
  Typography,
} from 'cfa-react-components';
import { useState } from 'react';
import InputRow from '../InputRow';

type InputRow = {
  index: number;
  user: IdentityUser | null;
  role: Role | null;
  deleted: boolean;
  errorMessage?: string | null;
};

export type AddLeadershipListItemProps = {
  rolesList: Role[];
  currentDepartment: Department;
  allowAddPointOfContact: boolean;
};

export const AddPointOfContactListItem = ({
  rolesList,
  currentDepartment,
  allowAddPointOfContact,
}: AddLeadershipListItemProps) => {
  const dispatch = useAppDispatch();
  const existingContacts = useAppSelector(
    selectPointsOfContactByDeptId(currentDepartment?.id),
  );

  const [open, setOpen] = useState(false);
  const [inputRowList, setInputRowList] = useState<InputRow[]>([]);

  const createEmptyRow = (index: number): InputRow => ({
    index,
    user: null,
    role: null,
    deleted: false,
    errorMessage: null,
  });

  const isDuplicate = (user: IdentityUser | null) => {
    return user
      ? existingContacts.some(contact => contact.userId === user.GUID)
      : false;
  };

  const validateRow = (
    updatedList: InputRow[],
    index: number,
    user: IdentityUser | null,
    role: Role | null,
  ): string | null => {
    if (!user) return null;

    if (isDuplicate(user)) {
      return 'Point of contact already exists for this subdepartment.';
    }

    const isUserDuplicateInForm = updatedList.some(
      (row, i) => i !== index && row.user?.GUID === user.GUID,
    );

    if (isUserDuplicateInForm) {
      return 'This user has already been added.';
    }

    return null;
  };

  const handleChangeInputRow = (
    index: number,
    tempRole: Role | null,
    tempUser: IdentityUser | null,
    from: string,
  ) => {
    const tempInputRows = [...inputRowList];
    if (from == 'USER') {
      tempInputRows[index] = {
        index: tempInputRows[index].index,
        role: tempInputRows[index].role,
        user: tempUser,
        deleted: false,
        errorMessage: tempInputRows[index].errorMessage,
      };
    }
    if (from == 'ROLE') {
      tempInputRows[index] = {
        index: tempInputRows[index].index,
        role: tempRole,
        user: tempInputRows[index].user,
        deleted: false,
        errorMessage: tempInputRows[index].errorMessage,
      };
    }
    tempInputRows[index].errorMessage = validateRow(
      tempInputRows,
      index,
      tempInputRows[index].user,
      tempInputRows[index].role,
    );
    setInputRowList(tempInputRows);
  };
  const handleDeleteInputRow = (
    indexToDelete: number,
    user: IdentityUser | null,
  ) => {
    const tempInputRows = [...inputRowList];
    tempInputRows[indexToDelete] = {
      index: tempInputRows[indexToDelete].index,
      role: tempInputRows[indexToDelete].role,
      user: tempInputRows[indexToDelete].user,
      deleted: true,
    };
    setInputRowList(tempInputRows);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setInputRowList([createEmptyRow(0)]);
    }, 0);
  };

  const handleClose = () => {
    setOpen(false);
    setInputRowList([]);
  };

  const handleAddRow = () => {
    setInputRowList([...inputRowList, createEmptyRow(inputRowList.length)]);
  };

  const handleSaveClose = async () => {
    const promises = inputRowList.map(row => {
      if (!row.user || !row.role || row.errorMessage) return null;
      return dispatch(
        createPointOfContact(row.user, currentDepartment, row.role),
      ).catch(error => {
        displayErrorToast(
          error.message,
          ACTIONS.createPointOfContact,
          row.user!.GUID,
        );
      });
    });

    await Promise.all(promises.filter(Boolean));
    handleClose();
  };

  const isSaveDisabled = inputRowList.some(
    row => !row.deleted && (!row.user || !row.role || row.errorMessage),
  );

  return (
    <>
      {allowAddPointOfContact && (
        <ListItem
          aria-label="Point Of Contact Actions"
          onClick={handleClickOpen}
          disabled={!allowAddPointOfContact}
          style={{ marginTop: '10px', paddingLeft: '20px' }}
          startItem={
            <Icon icon={UserPlus} size="md" style={{ marginBottom: '8px' }} />
          }
          endItem={
            <Icon icon={Plus} size="md" style={{ marginRight: '10px' }} />
          }
        >
          <Typography
            variant="body1"
            color="default"
            fontWeight="bold"
            align="left"
            data-cy="create-point-of-contact"
            style={{
              paddingTop: '30px',
              paddingLeft: '27px',
              marginTop: '-35px',
            }}
          >
            Add Point of Contact
          </Typography>
          <Divider
            variant="fullLength"
            style={{ paddingTop: '10px', marginLeft: '14px', width: '95%' }}
          />
        </ListItem>
      )}

      <Modal
        data-cy="close-add-point-of-contact"
        show={open}
        onClose={handleClose}
        size="lg"
        scrollMode="modal-body"
      >
        <>
          <ModalHeader>Add New Point Of Contact</ModalHeader>
          <Typography
            variant="body2"
            color="default"
            style={{ textAlign: 'center' }}
          >
            Fill out the required fields to add a point of contact to this
            subdepartment.
          </Typography>

          <ModalBody>
            <Container style={{ textAlign: 'left' }}>
              {inputRowList?.map(item => {
                if (item.deleted != true) {
                  return (
                    <>
                      <InputRow
                        key={item.index}
                        tempUser={item.user}
                        tempRole={item.role}
                        onDelete={() =>
                          handleDeleteInputRow(item.index, item?.user)
                        }
                        rolesList={rolesList}
                        index={item.index}
                        handleInputChange={handleChangeInputRow}
                      />
                      {item.errorMessage && (
                        <Typography
                          variant="body2"
                          color="primary"
                          data-cy={`row-error-${item.index}`}
                          style={{ marginTop: '10px', marginBottom: '15px' }}
                        >
                          {item.errorMessage}
                        </Typography>
                      )}
                    </>
                  );
                }
                return <></>;
              })}

              <div
                onClick={handleAddRow}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '20px',
                  cursor: 'pointer',
                }}
                data-cy="add-point-of-contact-btn"
              >
                <Icon
                  icon={Plus}
                  style={{
                    color: '$navy',
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                  height="1.5em"
                  width="1.5em"
                />
                <h4
                  data-cy="add-point-of-contact"
                  className="info"
                  style={{
                    marginLeft: '.75rem',
                    marginTop: '.15rem',
                  }}
                >
                  Add Point of Contact
                </h4>
              </div>
            </Container>
          </ModalBody>
          <div>
            <SaveAndCloseButtons
              saveUpdateGroup={handleSaveClose}
              allowEditGroup={isSaveDisabled}
              handleCloseGroup={handleClose}
              isDisabled={isSaveDisabled}
            />
            <div style={{ paddingBottom: '3rem' }} />
          </div>
        </>
      </Modal>
    </>
  );
};

export default AddPointOfContactListItem;
