import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';

export const useRevertEdit = (
  ref: RefObject<HTMLElement>,
  setEditMode: Dispatch<SetStateAction<boolean>>,
  targetClass: string,
  handleClick?: () => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        ref.current &&
        !ref.current.contains(target) &&
        !target.closest('li') && // Click is not inside an <li> element
        !target?.classList.contains(targetClass)
      ) {
        setEditMode(false);
        if (handleClick) {
          handleClick();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setEditMode, targetClass, handleClick]);
};
