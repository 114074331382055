import { AppAction } from 'app/store';
import { BreadcrumbProps } from 'cfa-react-components';
import {
  clearBreadcrumbs,
  popBreadcrumb,
  pushBreadcrumb,
  replaceBreadcrumbs,
  setIsNavigatingBack,
} from './slice';

export const pushBreadcrumbAction =
  (breadcrumb: BreadcrumbProps): AppAction<void> =>
  dispatch => {
    dispatch(pushBreadcrumb(breadcrumb));
  };

export const popBreadcrumbAction = (): AppAction<void> => dispatch => {
  dispatch(popBreadcrumb());
};

export const replaceBreadcrumbsAction =
  (breadcrumbs: BreadcrumbProps[]): AppAction<void> =>
  dispatch => {
    dispatch(replaceBreadcrumbs(breadcrumbs));
  };

export const clearBreadcrumbAction = (): AppAction<void> => dispatch => {
  dispatch(clearBreadcrumbs());
};

export const setIsNavigatingBackAction =
  (isNavigatingBack: boolean): AppAction<void> =>
  dispatch => {
    dispatch(setIsNavigatingBack(isNavigatingBack));
  };
