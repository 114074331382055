import { ExternalLink, Pencil } from '@cfa-icons/system';
import { DescriptorItem } from 'app/types/Descriptors';
import { useRevertEdit } from 'app/utils/hooks/useRevertEdit';
import { Card, Icon, Typography } from 'cfa-react-components';
import { useRef, useState } from 'react';
import '../../../../App.scss';
import './DescriptorBox.scss';
import { EditDescriptor } from './EditDescriptor';

export type DescriptorBoxProps = {
  name: string;
  items: DescriptorItem[];
};

export const DescriptorBox = ({ name, items }: DescriptorBoxProps) => {
  const [descriptorEditMode, setDescriptorEditMode] = useState(false);
  const [valueError, setValueError] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    const hasEmptyValue = items.some(item => item.value.length === 0);
    setValueError(hasEmptyValue);
    setDescriptorEditMode(hasEmptyValue);
  };

  useRevertEdit(cardRef, setDescriptorEditMode, 'infoPencil', handleClick);

  const toggleDescriptorEditMode = () => {
    setDescriptorEditMode(prev => !prev);
  };

  return (
    <Card
      ref={cardRef}
      className="descriptor-box"
      style={{
        maxWidth: descriptorEditMode ? 'inherit' : '500px',
        overflowY: descriptorEditMode ? 'auto' : 'hidden',
      }}
      variant="default"
      elevation={1}
    >
      <Icon
        onClick={toggleDescriptorEditMode}
        className="descriptor-box-edit-icon"
        data-cy="descriptor-box-edit-icon"
        icon={Pencil}
        size="sm"
        color="grey"
      />
      {descriptorEditMode ? (
        items.map((item, index) => (
          <EditDescriptor
            key={index}
            descriptorName={name}
            index={index}
            item={item}
            valueError={valueError && item.value.length == 0 ? true : false}
          />
        ))
      ) : (
        <>
          <Typography data-cy="descriptor-box">
            <Typography as="span" fontWeight="medium">
              {name}:{' '}
            </Typography>
            {items.map((item, index) => {
              const url = item.link?.startsWith('http')
                ? item.link
                : `https://${item.link}`;
              return (
                <span key={index}>
                  {item.link ? (
                    <>
                      <a className="descriptor-link" href={url} target="_blank">
                        {item.value}
                        <Icon
                          style={{ width: '1rem', padding: '0 0 3px 5px' }}
                          icon={ExternalLink}
                        />
                      </a>
                    </>
                  ) : (
                    item.value
                  )}
                  {index !== items.length - 1 ? ', ' : ''}
                </span>
              );
            })}
          </Typography>
        </>
      )}
    </Card>
  );
};

export default DescriptorBox;
