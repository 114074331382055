import { ChevronLeft } from '@cfa-icons/system';
import Navigation from 'app/components/Navigation/Navigation';
import EmployeePicture from 'app/components/shared/EmployeePicture';
import LeadershipCard from 'app/components/shared/LeadershipCard/LeadershipCard';
import PodCard from 'app/components/shared/PodCard/PodCard';
import PointsOfContactCard from 'app/components/shared/PointsOfContactCard/PointsOfContactCard';
import ApiState from 'app/constants/ApiState';
import memberApi from 'app/services/MembersApi';
import { useAppDispatch, useAppSelector } from 'app/store';
// import { setBreadcrumbAction } from 'app/store/breadcrumbs/actions';
import {
  popBreadcrumbAction,
  pushBreadcrumbAction,
  setIsNavigatingBackAction,
} from 'app/store/breadcrumbs/actions';
import {
  selectIsNavigatingBack,
  useBreadcrumbs,
} from 'app/store/breadcrumbs/selectors';
import { selectDepartments } from 'app/store/dept/selectors';
import { selectLeadership } from 'app/store/leadership/selectors';
import { selectPods } from 'app/store/pods/selectors';
import { selectPointsOfContact } from 'app/store/points_of_contact/selectors';
import {
  useLeadershipRoles,
  usePodRoles,
  usePointOfContactRoles,
} from 'app/store/roles/selectors';
import {
  selectLoadUserStatus,
  selectUserById,
} from 'app/store/users/selectors';
import Department from 'app/types/Department';
import LeadershipTeam from 'app/types/Leadership';
import Member from 'app/types/Member';
import MemberType from 'app/types/MemberType';
import Pod from 'app/types/Pod';
import Role from 'app/types/Role';
import { titleCase } from 'app/utils/namingFormat';
import {
  Breadcrumbs,
  Card,
  Divider,
  Icon,
  List,
  ListItem,
  LoadingIndicator,
  Typography,
} from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { handleBackToSearchResults } from '../../utils/breadcrumbSessionStorage';
import PageNotFound from '../PageNotFound/PageNotFound';
import './ProfilePage.scss';

export const ProfilePage = () => {
  const { profileId } = useParams() as { profileId: string };
  const navigate = useNavigate();
  const userStatus = useAppSelector(selectLoadUserStatus);
  const deptList: Department[] = useAppSelector(selectDepartments);

  const user = useAppSelector(selectUserById(profileId));
  const [involvements, setInvolvements] = useState<Member[]>([]);
  const pods = useAppSelector(selectPods);
  const leadershipTeams = useAppSelector(selectLeadership);
  const pointsOfContact = useAppSelector(selectPointsOfContact);
  const isNavigatingBack = useAppSelector(selectIsNavigatingBack);

  const newRoleList = usePodRoles();
  const newLeadershipRoleList = useLeadershipRoles();
  const newPointOfContactRoleList = usePointOfContactRoles();
  const [newPods, setNewPods] = useState<Pod[]>([]);
  const [newLeadershipTeams, setNewLeadershipTeams] = useState<
    LeadershipTeam[]
  >([]);
  const [newPointsOfContact, setNewPointsOfContact] = useState<Member[]>([]);
  const { breadcrumbs } = useBreadcrumbs();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [backBreadcrumbBoolean, setBreadcrumbBoolean] = useState<boolean>();
  const [combinedRoles, setCombinedRoles] = useState<Map<Role, Member[]>>(
    new Map(),
  );

  const sortInvolvements = (involvements: Member[]) => {
    let filteredPods: Pod[] = [];
    let filteredLeadership: LeadershipTeam[] = [];
    let filteredPointsOfContact: Member[] = [];
    involvements?.forEach(involvement => {
      if (involvement.type == MemberType.PODS) {
        const pod = pods.find(p => p.id === involvement.groupId);
        if (pod) {
          filteredPods.push(pod);
        }
      } else if (involvement.type == MemberType.LEADERSHIP) {
        const leadership = leadershipTeams.find(
          lt => lt.id === involvement.groupId,
        );
        if (leadership) {
          filteredLeadership.push(leadership);
        }
      } else if (involvement.type == MemberType.POINT_OF_CONTACT) {
        // const involvement = pointsOfContact.find(p => p.userId === involvement.userId);
        if (involvement) {
          filteredPointsOfContact.push(involvement);
        }
      }
    });
    return { filteredPods, filteredLeadership, filteredPointsOfContact };
  };

  const addPointOfContactToMap = (
    map: Map<Role, Member[]>,
    pointOfContact: Member,
    roleName: Role,
  ) => {
    if (map.has(roleName)) {
      let tempArray = [...map.get(roleName)!];
      tempArray.push(pointOfContact);
      map.set(roleName, tempArray);
    } else {
      map.set(roleName, [pointOfContact]);
    }
  };

  const combinePointOfContact = (pointOfContactList: Member[]) => {
    let tempMap: Map<Role, Member[]> = new Map();

    pointOfContactList.forEach(pointOfContact => {
      const roleId = pointOfContact.roleId;
      const role = newPointOfContactRoleList.find(role => role.id === roleId);
      if (role) {
        addPointOfContactToMap(tempMap, pointOfContact, role);
      }
    });
    setCombinedRoles(tempMap);
  };

  useEffect(() => {
    if (newPointsOfContact.length > 0) {
      combinePointOfContact(newPointsOfContact);
    }
  }, [newPointsOfContact]);

  const fromSearch = sessionStorage.getItem('fromSearch');

  useEffect(() => {
    if (user != undefined) {
      memberApi.members
        .getMember(profileId)
        .then(data => {
          setInvolvements(data);
        })
        .catch(e => {
          setInvolvements([]);
        });
    }

    if (fromSearch === 'true') {
      handleBackToSearchResults(dispatch, navigate);
    } else {
      if (!user || isNavigatingBack) {
        return;
      } else {
        const profileBreadcrumb = {
          label: user?.displayName || '',
          onClick: () => {
            navigate(`/profile/${profileId}`);
            dispatch(popBreadcrumbAction());
            dispatch(popBreadcrumbAction());
          },
        };

        const breadcrumbExists = breadcrumbs.some(
          crumb => crumb.label === profileBreadcrumb.label,
        );
        if (!breadcrumbExists) {
          dispatch(pushBreadcrumbAction(profileBreadcrumb));
        }
      }
    }
  }, []);

  useEffect(() => {
    const { filteredPods, filteredLeadership, filteredPointsOfContact } =
      sortInvolvements(involvements);
    setNewPods(filteredPods);
    setNewLeadershipTeams(filteredLeadership);
    setNewPointsOfContact(filteredPointsOfContact);
    setLoading(false);
  }, [involvements]);

  const handleBackClick = () => {
    dispatch(setIsNavigatingBackAction(true)); // Set the flag
    dispatch(popBreadcrumbAction());
    window.history.back(); // Go back to the previous page

    // Clear the flag after a short delay to allow the component to re-render
    setTimeout(() => {
      dispatch(setIsNavigatingBackAction(false));
    }, 0);
  };

  if (userStatus.state == ApiState.LOADING) {
    return (
      <div className="loading">
        <LoadingIndicator variant={'page'}></LoadingIndicator>
      </div>
    );
  } else if (userStatus.state == ApiState.IDLE && user) {
    return (
      <>
        <div className="main-page">
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className="navigation-breadcrumbs-wrapper">
              <Navigation />
              {fromSearch && (
                <>
                  <Icon icon={ChevronLeft} className="info" size="sm" />
                </>
              )}
              {breadcrumbs.length > 3 ? (
                <>
                  <Icon icon={ChevronLeft} className="info" size="sm" />
                  <Breadcrumbs
                    maxItems={3}
                    breadcrumbs={[
                      {
                        label: 'Back',
                        onClick: handleBackClick,
                      },
                    ]}
                  />
                </>
              ) : (
                <Breadcrumbs
                  data-cy="profile-breadcrumb"
                  onClick={undefined}
                  maxItems={4}
                  breadcrumbs={breadcrumbs}
                />
              )}
            </div>
            <Typography
              variant="h2"
              color="primary"
              data-cy="user-name"
              style={{
                textAlign: 'center',
                paddingBottom: '20px',
                marginTop: '20px',
                marginBottom: '5px',
              }}
            >
              {user?.displayName}
              {user?.company && user.AUDIENCE_TAG === 'CONTRACTOR_AUDIENCE' ? (
                <Link
                  to={`/company/${encodeURIComponent(user.company)}`}
                  data-cy="user-company"
                  className="company-name"
                  style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {' '}
                  ({titleCase(user.company)})
                </Link>
              ) : null}
            </Typography>
            <EmployeePicture
              user={user!}
              imgWidth={'200px'}
              imgHeight={'200px'}
            />
            <Typography
              variant="h2"
              color="primary"
              style={{
                textAlign: 'center',
                paddingBottom: '20px',
                marginTop: '20px',
                marginBottom: '5px',
              }}
            >
              Current Involvements
            </Typography>
            {loading && <LoadingIndicator variant="inline" />}
            {!loading && (
              <div
                className="current-involvements"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {loading && <LoadingIndicator variant="inline" size="md" />}
                {newPods.length > 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingRight: '30px',
                      width: '300px',
                    }}
                  >
                    <Typography
                      variant="h4"
                      color="secondary"
                      style={{
                        textAlign: 'center',
                        marginTop: '20px',
                        marginBottom: '5px',
                      }}
                    >
                      Pods
                    </Typography>
                    <Divider
                      orientation="horizontal"
                      variant="fullLength"
                      style={{
                        width: '200px',
                      }}
                    />
                    <List>
                      {newPods.map(pod => (
                        <ListItem
                          key={pod.id}
                          style={{
                            minWidth: '280px',
                          }}
                        >
                          <PodCard rolesList={newRoleList} pod={pod} />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                ) : (
                  <></>
                )}
                {!loading && newLeadershipTeams.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingRight: '30px',
                      width: '300px',
                    }}
                  >
                    <Typography
                      variant="h4"
                      color="secondary"
                      style={{
                        textAlign: 'center',
                        marginTop: '20px',
                        marginBottom: '5px',
                      }}
                    >
                      Leadership Teams
                    </Typography>
                    <Divider
                      orientation="horizontal"
                      variant="fullLength"
                      style={{
                        width: '200px',
                      }}
                    />
                    <List>
                      {newLeadershipTeams.map(team => (
                        <ListItem
                          key={team.id}
                          style={{
                            minWidth: '280px',
                          }}
                          onClick={() => {
                            console.log('HELLO');
                          }}
                        >
                          <LeadershipCard
                            onProfile
                            rolesList={newLeadershipRoleList}
                            deptList={deptList}
                            leadership={team}
                            leadershipMembers={team.members ?? []}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )}
                {!loading && newPointsOfContact.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingRight: '30px',
                      width: '300px',
                    }}
                  >
                    <Typography
                      variant="h4"
                      color="secondary"
                      style={{
                        textAlign: 'center',
                        marginTop: '20px',
                        marginBottom: '5px',
                      }}
                    >
                      Points of Contact
                    </Typography>
                    <Divider
                      orientation="horizontal"
                      variant="fullLength"
                      style={{
                        width: '200px',
                      }}
                    />
                    <List>
                      {[...combinedRoles].map(([role, contacts]) => {
                        const uniqueDepartments = Array.from(
                          new Set(contacts.flatMap(contact => contact.groupId)),
                        );
                        return (
                          <ListItem
                            key={role.name}
                            style={{
                              minWidth: '280px',
                            }}
                          >
                            <PointsOfContactCard
                              onProfile
                              rolesList={newPointOfContactRoleList}
                              deptList={deptList}
                              roleName={role.name}
                              uniqueDepartments={uniqueDepartments}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                )}
              </div>
            )}
          </Card>
        </div>
      </>
    );
  } else {
    return <PageNotFound></PageNotFound>;
  }
};

export default ProfilePage;
