import { IconTrashFilled } from '@tabler/icons-react';
import { DescriptorItem, Descriptors } from 'app/types/Descriptors';
import { Checkbox, Col, Dropdown, Icon, TextField } from 'cfa-react-components';
import { useCallback, useContext, useState } from 'react';
import '../../../../App.scss';
import { DescriptorsContext } from './DescriptorsContext';
import './EditDescriptor.scss';

export type EditDescriptorProps = {
  item: DescriptorItem;
  index: number;
  descriptorName: string;
  valueError: boolean;
};

export const EditDescriptor = ({
  item,
  index,
  descriptorName,
  valueError,
}: EditDescriptorProps) => {
  const { descriptors, setDescriptors } = useContext(DescriptorsContext);
  const [includeLink, setIncludeLink] = useState(item.link ? true : false);

  const handleUpdate = (field: keyof DescriptorItem, newValue: string) => {
    if (!descriptors) {
      return;
    }

    const updatedDescriptors = {
      ...descriptors,
      [descriptorName]: (descriptors as Descriptors)[descriptorName].map(
        (descItem, i) =>
          i === index ? { ...descItem, [field]: newValue } : descItem,
      ),
    };

    setDescriptors(updatedDescriptors);
  };

  const toggleIncludeLink = useCallback(() => {
    setIncludeLink(prevIncludeLink => {
      const newState = !prevIncludeLink;
      if (!newState) {
        handleUpdate('link', '');
      }
      return newState;
    });
  }, []);

  const onDelete = (index: number) => {
    if (!descriptors) {
      return;
    }

    const updatedItems = (descriptors as Descriptors)[descriptorName].filter(
      (_, i) => i !== index,
    );

    if (updatedItems.length) {
      setDescriptors({ ...descriptors, [descriptorName]: updatedItems });
    } else {
      const { [descriptorName]: _, ...rest } = descriptors;
      setDescriptors(rest);
    }
  };

  return (
    <div key={index} className="edit-descriptor-row">
      <Col>
        <Dropdown
          className="descriptor-name-dropdown"
          label="Descriptor"
          required
          disabled
          fullWidth
          options={Object.keys(descriptors as Descriptors)}
          value={descriptorName}
          onChange={() => {}}
        />
      </Col>
      <Col>
        <TextField
          onChange={e => handleUpdate('value', e.target.value)}
          label="Value"
          value={item.value}
          fullWidth
          required
          maxLength={50}
          helperText=" "
          {...(valueError && {
            errorText: 'You must enter a value',
          })}
        />
        {includeLink && (
          <TextField
            className="link-text-field"
            onChange={e => handleUpdate('link', e.target.value)}
            label="Link"
            value={item.link || ''}
            fullWidth
            helperText=" "
          />
        )}
        <Checkbox
          label="Include Link"
          checked={includeLink}
          onChange={toggleIncludeLink}
          crossOrigin={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      </Col>
      <div style={{ marginTop: '35px' }}>
        <Icon
          onClick={() => {
            onDelete(index);
          }}
          icon={IconTrashFilled}
          style={{
            cursor: 'pointer',
          }}
          data-cy={`descriptor-delete-icon-${index}`}
        />
      </div>
    </div>
  );
};

export default EditDescriptor;
