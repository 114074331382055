import { useAppDispatch, useAppSelector } from 'app/store';
import {
  popBreadcrumbAction,
  pushBreadcrumbAction,
} from 'app/store/breadcrumbs/actions';
import { useBreadcrumbs } from 'app/store/breadcrumbs/selectors';
import { selectUserById } from 'app/store/users/selectors';
import Member from 'app/types/Member';
import { removeCompanySuffix } from 'app/utils/namingFormat';
import { Avatar, Tag } from 'cfa-react-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../App.scss';
import './ProfileTag.scss';

// This and the leadershipProfileCard are the same
export type ProfileProps = {
  member: Member;
};
// The values are arguments passed from the PodCardRoleSection.js file.
export const ProfileTag = ({ member }: ProfileProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { breadcrumbs } = useBreadcrumbs();
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    userId: string | undefined,
  ) => {
    event?.stopPropagation();
    if (userId) {
      const profileTagBreadcrumb = {
        label: member.name || '',
        onClick: () => {
          navigate(`/profile/${userId}`);
          dispatch(popBreadcrumbAction());
        },
      };

      dispatch(pushBreadcrumbAction(profileTagBreadcrumb));
      navigate(`/profile/${userId}`);
    }
  };
  const user = useAppSelector(selectUserById(member.userId));

  return (
    <div>
      {member.isContingent &&
      location.pathname === `/profile/${member.userId}` ? (
        <Tag
          className="tag"
          color="tan"
          variant="filled"
          label={member.name}
          data-cy="contingent-user-tag"
          leadingElement={
            <Avatar
              className="user-icon"
              color="secondary"
              size="lg"
              variant="outlined"
              data-cy="user-icon"
            />
          }
          style={{
            marginTop: 7,
            marginBottom: 7,
            paddingRight: 9,
            paddingLeft: 5,
          }}
          onClick={e => handleClick(e, member.userId)}
        />
      ) : member.isContingent ? (
        <Tag
          className="tag"
          color="tan"
          variant="filled"
          label={
            user
              ? `${member.name} (${removeCompanySuffix(user?.company)})`
              : `${member.name}`
          }
          data-cy="contingent-tag"
          style={{ marginTop: 7, marginBottom: 7 }}
          onClick={e => handleClick(e, member.userId)}
        />
      ) : location.pathname === `/profile/${member.userId}` ? (
        <>
          <Tag
            color="default"
            label={member.name}
            leadingElement={
              <Avatar
                className="user-icon"
                color="secondary"
                size="lg"
                variant="outlined"
                data-cy="user-icon"
              />
            }
            data-cy="user-tag"
            style={{
              marginTop: 7,
              marginBottom: 7,
              paddingRight: 9,
              paddingLeft: 5,
            }}
            onClick={e => handleClick(e, member.userId)}
          />
        </>
      ) : (
        <Tag
          color="default"
          label={member.name}
          data-cy="tag"
          style={{ marginTop: 7, marginBottom: 7 }}
          onClick={e => handleClick(e, member.userId)}
        />
      )}
    </div>
  );
};
export default ProfileTag;
